import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.to-string";

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
import { getZoneId } from "../utils/auth";
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * ログイン時のユーザ名はメールアドレスのみ
 * @param {string} str
 * @returns {Boolean}
 */

export function validUsername(str) {
  return str.trim().length > 0;
}
/**
 * @param {string} url
 * @returns {Boolean}
 */

export function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} email
 * @returns {Boolean}
 */

export function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }

  return false;
}
/**
 * @param {Array} arg
 * @returns {Boolean}
 */

export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  return Array.isArray(arg);
}
export function depthFirstSearch(affiliations, affiliation, optionId) {
  var result = [];
  var treeMapAffiliation = [];

  function indexWhere(array, conditionFn) {
    var item = array.find(conditionFn);
    return array.indexOf(item);
  }

  affiliations.map(function (item) {
    return affiliationSearch(item);
  });

  function affiliationSearch(object) {
    if (treeMapAffiliation) {
      var indexOfTreeAffiliation = indexWhere(treeMapAffiliation, function (item) {
        return item.parent_id === object.parent_id;
      });

      if (indexOfTreeAffiliation >= 0) {
        treeMapAffiliation.splice(indexOfTreeAffiliation, treeMapAffiliation.length);
      }
    }

    treeMapAffiliation.push(object);

    if (affiliation.length && affiliation) {
      var checkNotEmpty = affiliation.filter(function (item) {
        return item.id.toString() === object.id.toString();
      });

      if (checkNotEmpty.length) {
        treeMapAffiliation.forEach(function (item) {
          var indexOfFloor = treeMapAffiliation.indexOf(item);
          item.paddingFloorInTreeAffiliation = indexOfFloor * 24;

          if (item.id === checkNotEmpty[0].id) {
            item.is_directly = true;
          }

          result.push(item);
        });
      }
    }

    return object.affiliations.map(function (ite) {
      return affiliationSearch(ite);
    });
  }

  return _toConsumableArray(new Set(result));
} // switch text by zone id

export function switchTitle() {
  var zoneId = getZoneId().trim(); // zone 1 -> hiroshima, zone 2 -> osaka

  switch (zoneId) {
    case '1':
      return {
        textBranchAreaByZone: '所属グループ',
        branchMassage: '支部',
        areaMessage: '地区',
        importSample: 'assets/import_sample_hiroshima.xlsx',
        branchName: '広島',
        exampleName: '広島東支部の広島東支部理事会(1103)',
        exampleNumber: '1064, 1103'
      };

    case '2':
      return {
        textBranchAreaByZone: 'ブロック&支部',
        branchMassage: 'ブロック',
        areaMessage: '支部',
        importSample: 'assets/import_sample.xlsx',
        branchName: '大阪',
        exampleName: '組織委員会(2150)',
        exampleNumber: '2067, 2150'
      };

    default:
      return {
        textBranchAreaByZone: '所属グループ',
        branchMassage: '支部',
        areaMessage: '地区',
        importSample: 'assets/import_sample_hiroshima.xlsx',
        branchName: '大阪',
        exampleName: '広島東支部の広島東支部理事会(1103)',
        exampleNumber: '1077, 1064, 1103'
      };
  }
} // check is number

export function checkIsNumber(value) {
  var regNumber = /^[0-9]+$/;

  try {
    if (regNumber.test(Number(value))) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
export function checkIsNumberAndComma(value) {
  var regNumber = /^[0-9.,]+$/;

  try {
    if (regNumber.test(value)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
export function extractExtAndNameFromFilename(fileName) {
  var extractFile = {
    nameFile: '',
    extension: ''
  };

  try {
    var file_name_array = fileName.split('.');
    extractFile.nameFile = file_name_array.slice(0, -1).join('.');
    extractFile.extension = file_name_array.pop();
    return extractFile;
  } catch (_unused) {
    return extractFile;
  }
}
export var urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#!=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#!?&//=]*)/g;
export var emailRegex = /([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/ig;
export var mobileRegex = /\s+((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})/g;