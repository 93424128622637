import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [{
  path: '/profile',
  component: Layout,
  redirect: '/',
  name: 'Profile',
  meta: {
    title: 'プロフィール',
    affix: true,
    icon: 'user'
  },
  children: [{
    path: '/profile',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'ProfileIndex',
    meta: {
      title: 'プロフィール',
      affix: true
    }
  }, {
    path: '/profile/roles',
    component: function component() {
      return import('@/views/company/roles/index');
    },
    name: 'RoleIndex',
    hidden: true,
    meta: {
      title: '社員権限',
      affix: true
    }
  }, {
    path: '/profile/roles/create',
    component: function component() {
      return import('@/views/company/roles/create');
    },
    name: 'CreateRole',
    hidden: true,
    meta: {
      title: '社員権限',
      affix: true
    }
  }, {
    path: '/profile/roles/edit',
    component: function component() {
      return import('@/views/company/roles/edit');
    },
    name: 'EditRole',
    hidden: true,
    meta: {
      title: '社員権限',
      affix: true
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/',
  name: 'Company',
  meta: {
    title: '社員管理',
    affix: true,
    icon: 'peoples'
  },
  children: [{
    path: '/company',
    component: function component() {
      return import('@/views/company/index');
    },
    name: 'CompanyIndex',
    meta: {
      title: '社員名簿',
      affix: true
    }
  }, {
    path: '/company/create',
    component: function component() {
      return import('@/views/company/create');
    },
    name: 'CompanyCreate',
    meta: {
      title: '社員登録',
      affix: true
    }
  }, {
    path: '/company/excel-import',
    component: function component() {
      return import('@/views/company/excel-import');
    },
    name: 'CompanyImport',
    meta: {
      title: '社員情報の取込み',
      affix: true
    }
  }, {
    path: '/company/edit',
    component: function component() {
      return import('@/views/company/edit');
    },
    hidden: true,
    name: 'CompanyAdminEdit',
    meta: {
      title: '社員情報の編集',
      affix: true
    }
  }]
}, {
  path: '/bulletin',
  component: Layout,
  redirect: '/',
  name: 'Bulletin',
  meta: {
    title: '掲示板',
    affix: true,
    icon: 'form'
  },
  children: [{
    path: '/bulletin',
    component: function component() {
      return import('@/views/bulletin/index');
    },
    name: 'BulletinIndex',
    meta: {
      title: '掲示板一覧',
      affix: true
    }
  }, {
    path: '/bulletin/create',
    component: function component() {
      return import('@/views/bulletin/create');
    },
    name: 'BulletinCreate',
    meta: {
      title: '掲示板投稿',
      affix: true
    }
  }, {
    path: '/bulletin/edit',
    component: function component() {
      return import('@/views/bulletin/edit');
    },
    name: 'BulletinEdit',
    hidden: true,
    meta: {
      title: '掲示板詳細',
      affix: true
    }
  }, {
    path: '/bulletin/category',
    component: function component() {
      return import('@/views/bulletin/category/index');
    },
    name: 'CategoryIndex',
    meta: {
      title: 'カテゴリーリスト',
      affix: true
    }
  }]
}, {
  path: '/suggestion',
  component: Layout,
  redirect: '/',
  name: 'Suggestion',
  meta: {
    title: '目安箱',
    affix: true,
    icon: 'mailbox'
  },
  children: [{
    path: '/suggestion',
    component: function component() {
      return import('@/views/suggestion/index');
    },
    name: 'SuggestionIndex',
    meta: {
      title: '目安箱一覧',
      affix: true
    }
  }, {
    path: '/suggestion/components/detail',
    component: function component() {
      return import('@/views/suggestion/components/detail');
    },
    name: 'SuggestionDetail',
    hidden: true,
    meta: {
      title: '目安箱詳細',
      affix: true
    }
  }, {
    path: '/suggestion/components/category',
    component: function component() {
      return import('@/views/suggestion/components/category');
    },
    name: 'SuggestionCategory',
    meta: {
      title: 'カテゴリーリスト',
      affix: true
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  name: 'Error',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;