import _taggedTemplateLiteral from "/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/esm/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\nmutation deleteCategoryAndUpdateBulletinCategory($category_id: Int) {\n  update_bulletin(where: {category_id: {_eq: $category_id}}, _set: {category_id: null}) {\n    affected_rows\n  }\n  delete_bulletin_categories(where: {id: {_eq: $category_id}}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\nmutation reInsertBulletinSurveyMutation($delete_survey_ids: [Int!]!, $bulletin_id: Int!, $insert_bulletin_survey: [bulletin_survey_insert_input!]!) {\n  delete_survey_answer_user(where: {survey_answer: {survey_id: {_in: $delete_survey_ids}}}) {\n    affected_rows\n  }\n  delete_survey_answer(where: {survey_id: {_in: $delete_survey_ids}}) {\n    affected_rows\n  }\n  delete_bulletin_survey(where: {bulletin_id: {_eq: $bulletin_id}}) {\n    affected_rows\n  }\n  delete_survey(where: {id: {_in: $delete_survey_ids}}) {\n    affected_rows\n  }\n  insert_bulletin_survey(objects: $insert_bulletin_survey) {\n    affected_rows\n  }\n}\n\n\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\nmutation insertBulletinSurveyOne($bulletin_id: Int!, $survey_id: Int!) {\n  insert_bulletin_survey_one(object: {bulletin_id: $bulletin_id, survey_id: $survey_id}) {\n    id\n  }\n}\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\nmutation insertSurveyOne($choice_type: Int!, $question: String!, $data: [survey_answer_insert_input!]!, $is_public_result: Boolean!) {\n  insert_survey_one(object: {choice_type: $choice_type, question: $question, survey_answers: {data: $data}, is_public_result: $is_public_result}) {\n    id\n  }\n}\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\nmutation update_bulletin_categories($id: Int!, $name: String!) {\n  update_bulletin_categories_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\nmutation insertBulletinCategories($name: String!) {\n  insert_bulletin_categories_one(object: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\nmutation deleteSurveyMutation($survey_id: [Int!]!, $survey_answer_id: [Int!]!) {\n  delete_survey_answer_user(where: {survey_answer_id: {_in: $survey_answer_id}}) {\n    affected_rows\n  }\n  delete_survey_answer(where: {survey_id: {_in: $survey_id}}) {\n    affected_rows\n  }\n  delete_bulletin_survey(where: {survey_id: {_in: $survey_id}}) {\n    affected_rows\n  }\n  delete_survey(where: {id: {_in: $survey_id}}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\nmutation updateBulletinMutation($bulletinId: Int!) {\n  update_bulletin_by_pk(pk_columns: {id: $bulletinId}, _set: {deleted_at: \"now()\"}) {\n    id\n  }\n  delete_bulletin_files(where: {bulletin_id: {_eq: $bulletinId}}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\nmutation updateBulletin($id: Int!, $push_notification: Boolean!, $content: String!, $title: String!, $objects: [bulletin_files_insert_input!]!, $categoryId: Int!, $publish_at: timestamptz!) {\n  update_bulletin(where: {id: {_eq: $id}}, _set: {content: $content, title: $title, push_notification: $push_notification, category_id: $categoryId, publish_at: $publish_at}) {\n    affected_rows\n  }\n  delete_bulletin_files(where: {bulletin_id: {_eq: $id}}) {\n    affected_rows\n  }\n  insert_bulletin_files(objects: $objects) {\n    affected_rows\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nmutation bulletinMutation($content: String!, $push_notification: Boolean!, $title: String!, $attach_files: [bulletin_files_insert_input!]!, $categoryId: Int!, $publish_at: timestamptz) {\n  insert_bulletin_one(object: {content: $content, push_notification: $push_notification, title: $title, publish_at: $publish_at, attach_files: {data: $attach_files}, category_id: $categoryId}) {\n    id\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from 'graphql-tag';
export var ADD_BULLETIN = gql(_templateObject());
export var UPDATE_BULLETIN = gql(_templateObject2());
export var UPDATE_BULLETIN_BY_ID = gql(_templateObject3());
export var DELETE_SURVEY = gql(_templateObject4());
export var ADD_BULLETIN_CATEGORIES = gql(_templateObject5());
export var UPDATE_BULLETIN_CATEGORIES_BY_ID = gql(_templateObject6());
export var INSERT_SURVEY_ONE = gql(_templateObject7());
export var INSERT_BULLETIN_SURVEY_ONE = gql(_templateObject8());
export var REINSERT_BULLETIN_SURVEY = gql(_templateObject9());
export var DELETE_CATEGORY_AND_UPDATE_BULLETIN_CATEGORY_BY_ID = gql(_templateObject10());