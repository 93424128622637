import _taggedTemplateLiteral from "/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/esm/taggedTemplateLiteral";

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\nmutation DELETE_EMPLOYEES_BY_DELETED_AT($uid: uuid!) {\n  update_user_by_pk(pk_columns: {uid: $uid}, _set: {deleted_at: \"now()\", is_active: false, is_display: false}) {\n    id\n  }\n  update_room_member(where: {user_id: {_eq: $uid}, chat_room: {type: {_eq: 3}}}, _set: {deleted_at: \"now()\"}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\nmutation insert_user_role_chat_policy($objects: [user_role_chat_policy_insert_input!]!) {\n  insert_user_role_chat_policy(objects: $objects, on_conflict: {constraint: user_role_chat_policy_user_id_role_id_block_key, update_columns: is_active}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\nmutation deleteRolesMutation($id: Int!) {\n  delete_roles_by_pk(id: $id) {\n    id\n  }\n}\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\nmutation updateRoleMutation($id: Int!, $roles: roles_set_input!) {\n  update_roles_by_pk(pk_columns: {id: $id}, _set: $roles) {\n    id\n  }\n}\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\nmutation insertRoleMutation($roles: roles_insert_input!) {\n  insert_roles_one(object: $roles) {\n    id\n  }\n}\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\nmutation userAffilication($user_id: uuid!, $objects: [user_affiliation_insert_input!]!,$affiliation_ids: [Int!]!) {\n  delete_user_affiliation(where: {user_id: {_eq: $user_id}, affiliation_id: {_nin: $affiliation_ids}}) {\n    affected_rows\n  }\n  insert_user_affiliation(objects: $objects, on_conflict: {constraint: user_affiliation_user_id_affiliation_id_key, update_columns: is_directly}) {\n    affected_rows\n    returning {\n      id\n    }\n  }\n}\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\nmutation deleteUserAffiliation($user_id: uuid!, $affiliation_ids: [Int!]!) {\n  delete_user_affiliation(where: {user_id: {_eq: $user_id}, affiliation_id: {_nin: $affiliation_ids}}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\nmutation insertUserAffiliation($objects: [user_affiliation_insert_input!]!) {\n  insert_user_affiliation(objects: $objects, on_conflict: {constraint: user_affiliation_user_id_affiliation_id_key, update_columns: updated_at}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\nmutation updateCompany(\n  $id: Int\n  $name: String\n  $name_furigana: String\n  $phone_number: String\n  $mobile_phone: String\n  $fax: String\n  $email: String\n  $website: String\n  $postcode: String\n  $address_1: String\n  $address_2: String\n  $business_descriptions: String\n  $capital_stock: String\n  $employees: Int\n  $established: date\n  $join_date: date\n) {\n  update_company(where: {id: {_eq: $id}},\n    _set: {\n      name: $name\n      name_furigana: $name_furigana\n      phone_number: $phone_number\n      mobile_phone: $mobile_phone\n      fax: $fax\n      email: $email\n      website: $website\n      postcode: $postcode\n      address_1: $address_1\n      address_2: $address_2\n      business_descriptions: $business_descriptions\n      capital_stock: $capital_stock\n      employees: $employees\n      established: $established\n      join_date: $join_date\n    }\n  ) {\n    affected_rows\n  }\n}\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\nmutation updateCompany(\n  $id: Int\n  $name: String\n  $name_furigana: String\n  $phone_number: String\n  $mobile_phone: String\n  $fax: String\n  $email: String\n  $website: String\n  $postcode: String\n  $address_1: String\n  $address_2: String\n  $business_descriptions: String\n  $capital_stock: String\n  $employees: Int\n  $employees_part_time: Int\n  $established: date\n  $join_date: date\n) {\n  update_company(where: {id: {_eq: $id}},\n    _set: {\n      name: $name\n      name_furigana: $name_furigana\n      phone_number: $phone_number\n      mobile_phone: $mobile_phone\n      fax: $fax\n      email: $email\n      website: $website\n      postcode: $postcode\n      address_1: $address_1\n      address_2: $address_2\n      business_descriptions: $business_descriptions\n      capital_stock: $capital_stock\n      employees: $employees\n      employees_part_time: $employees_part_time\n      established: $established\n      join_date: $join_date\n    }\n  ) {\n    affected_rows\n  }\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\nmutation updateMember(\n  $uid: uuid,\n  $objects: user_set_input\n) {\n  update_user(where: {uid: {_eq: $uid}},\n    _set: $objects\n  ) {\n    affected_rows\n  }    \n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\nmutation addCompany($objects: [user_insert_input!]!) {\n    insert_user(objects: $objects) {\n      returning {\n        id\n      }\n    }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\nmutation importMember($objects: [user_insert_input!]!) {\n  insert_user(\n    objects: $objects,\n    on_conflict:{\n      constraint: user_id_key1,\n      update_columns: []\n    }) {\n      returning {\n        id\n        uid\n      }\n    }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nmutation addMember($objects: [user_insert_input!]!) {\n  insert_user(objects: $objects) {\n      returning {\n        id\n        uid\n      }\n    }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from 'graphql-tag';
export var ADD_MEMBER = gql(_templateObject());
export var ADD_IMPORT_MEMBER = gql(_templateObject2());
export var ADD_COMPANY = gql(_templateObject3());
export var UPDATE_MEMBER = gql(_templateObject4());
export var UPDATE_COMPANY_ADMIN = gql(_templateObject5());
export var UPDATE_COMPANY = gql(_templateObject6());
export var USER_AFFILIATION_INSERT = gql(_templateObject7());
export var USER_AFFILIATION_DELETE = gql(_templateObject8());
export var USER_AFFILIATION_INSERT_UPDATE = gql(_templateObject9());
export var INSERT_ROLE = gql(_templateObject10());
export var UPDATE_ROLE = gql(_templateObject11());
export var DELETE_ROLES = gql(_templateObject12());
export var INSERT_USER_ROLE_CHAT_POLICY = gql(_templateObject13());
export var DELETE_EMPLOYEES_BY_DELETED_AT = gql(_templateObject14());