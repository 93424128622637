import _taggedTemplateLiteral from "/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/esm/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\nmutation update_suggestion_comments_by_pk($commentId: Int!, $comment: String!) {\n  update_suggestion_comments_by_pk(pk_columns: {id: $commentId}, _set: {comment: $comment}) {\n    id\n  }\n}\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\nmutation delete_suggestion_comments_by_pk($comment_id: Int!) {\n  delete_suggestion_comments_by_pk(id: $comment_id) {\n    id\n  }\n}\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\nmutation insert_suggestion_comments($comment: String!, $suggestion_id: Int!) {\n  insert_suggestion_comments(objects: {comment: $comment, suggestion_id: $suggestion_id}) {\n    affected_rows\n  }\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\nmutation deleteCategoryAndUpdateSuggestionCategory($category_id: Int!) {\n  update_suggestion(where: {category_id: {_eq: $category_id}}, _set: {category_id: null}) {\n    affected_rows\n  }\n  delete_suggestion_categories_by_pk(id: $category_id) {\n    id\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\nmutation update_suggestion_categories($id: Int!, $name: String!) {\n  update_suggestion_categories_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\nmutation insertSuggestionCategories($name: String!) {\n  insert_suggestion_categories_one(object: {name: $name}) {\n    id\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nmutation addSuggestionUser($suggestion_id: Int!) {\n    insert_suggestion_users_one(object: {suggestion_id: $suggestion_id}, on_conflict: {constraint: suggestion_users_suggestion_id_user_id_key, update_columns: updated_at}) {\n      id\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from 'graphql-tag';
export var ADD_SUGGESTION_USER = gql(_templateObject());
export var ADD_SUGGESTION_CATEGORIES = gql(_templateObject2());
export var UPDATE_SUGGESTION_CATEGORIES_BY_ID = gql(_templateObject3());
export var DELETE_CATEGORY_AND_UPDATE_SUGGESTION_CATEGORY_BY_ID = gql(_templateObject4());
export var INSERT_SUGGESTION_COMMENT = gql(_templateObject5());
export var DELETE_COMMENT_BY_ID = gql(_templateObject6());
export var UPDATE_COMMENT_BY_ID = gql(_templateObject7());