import _taggedTemplateLiteral from "/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/esm/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\nquery suggestion_comments($suggestion_id: Int!, $limit: Int) {\n  suggestion_comments(where: {suggestion_id: {_eq: $suggestion_id}}, order_by: {created_at: desc}, limit: $limit) {\n    id\n    comment\n    created_at\n    updated_at\n    user {\n      uid\n      get_avatar_thumbnail\n      name\n      last_name\n    }\n  }\n  suggestion_comments_aggregate(where: {suggestion_id: {_eq: $suggestion_id}}, limit: null) {\n    aggregate {\n      count\n    }\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\nquery getSuggestionCategories {\n  suggestionCategories: suggestion_categories(order_by: {created_at: desc}) {\n     id\n     name\n   }\n }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\nquery suggestionQueryById($id: Int!) {\n  suggestion: suggestion_by_pk(id: $id) {\n    content\n    title\n    created_at\n    author_id\n    publish_at\n    show_identity\n    id\n    category_id\n    attach_files(where: {suggestion_id: {_eq: $id}}, order_by: {created_at: desc}) {\n      file_name\n      attach_file_type\n      url\n    }\n    user {\n      name\n      last_name\n    }\n    category {\n      name\n    }\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nquery getSuggestions($keyword: String, $page_limit: Int, $page_offset: Int, $categoryId: Int) {\n  suggestions: search_suggestion_admin_site(args: {page_limit: $page_limit, page_offset: $page_offset, keyword: $keyword, categoryid: $categoryId}, order_by: {publish_at: desc}) {\n    content\n    title\n    publish_at\n    created_at\n    is_viewed\n    show_identity\n    id\n    category {\n      name\n    }\n    user {\n      name\n      last_name\n    }\n  }\n  suggestions_count: search_suggestion_admin_site_aggregate(args: {keyword: $keyword, categoryid: $categoryId, page_limit: null, page_offset: null}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from 'graphql-tag';
export var GET_SUGGESTION = gql(_templateObject());
export var GET_SUGGESTION_BY_ID = gql(_templateObject2());
export var GET_SUGGESTION_CATEGORIES = gql(_templateObject3());
export var GET_SUGGESTION_COMMENTS = gql(_templateObject4());