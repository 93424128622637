import _taggedTemplateLiteral from "/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/esm/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\nquery user($my_uid: uuid!) {\n  user: user_by_pk(uid: $my_uid) {\n    avatar\n  }\n}\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\nquery($id: String, $password: String) {\n    # user(where: {id: {_eq: $id}, _and: {_and: {password: {_eq: $password}, _and: {is_active: {_eq: true}}}}}) {\n    user(where: {id: {_eq: $id}, _and: {_and: {password: {_eq: $password}}}}) {\n      uid                 # UID\n    company_id          # \u4F1A\u793EID\n    avatar              # \u30A2\u30D0\u30BF\u30FCURL\n    last_name           # \u82D7\u5B57\n    last_name_furigana  # \u82D7\u5B57\uFF08\u3075\u308A\u304C\u306A\uFF09\n    name                # \u540D\u524D\n    name_furigana       # \u540D\u524D\uFF08\u3075\u308A\u304C\u306A\uFF09\n    position            # \u5F79\u8077\n    mobile_phone        # \u643A\u5E2F\u756A\u53F7\n    id                  # \u30ED\u30B0\u30A4\u30F3ID\n    password            # \u30ED\u30B0\u30A4\u30F3\u30D1\u30B9\u30EF\u30FC\u30C9\n    is_active           # \u30ED\u30B0\u30A4\u30F3\u53EF\u5426\uFF08true=\u53EF\u3001false=\u5426\uFF09\n    is_display          # \u4E00\u89A7\u8868\u793A\u975E\u8868\u793A\uFF08true=\u8868\u793A\u3001false=\u975E\u8868\u793A\uFF09\n  }\n}\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\nquery($id: String) {\n  user(where: {id: {_eq: $id}}) {\n    uid                 # UID\n    company_id          # \u4F1A\u793EID\n    avatar              # \u30A2\u30D0\u30BF\u30FCURL\n    last_name           # \u82D7\u5B57\n    last_name_furigana  # \u82D7\u5B57\uFF08\u3075\u308A\u304C\u306A\uFF09\n    name                # \u540D\u524D\n    name_furigana       # \u540D\u524D\uFF08\u3075\u308A\u304C\u306A\uFF09\n    position            # \u5F79\u8077\n    mobile_phone        # \u643A\u5E2F\u756A\u53F7\n    id                  # \u30ED\u30B0\u30A4\u30F3ID\n    password            # \u30ED\u30B0\u30A4\u30F3\u30D1\u30B9\u30EF\u30FC\u30C9\n  }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\nquery($id: String) {\n  user(where: {id: {_eq: $id}}) {\n    uid\n    avatar\n    position\n    company_id\n    mobile_phone\n    id\n    password\n    last_name\n    last_name_furigana\n    name\n    name_furigana\n    is_active\n    is_display\n    created_at\n    updated_at\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nquery getUser($uid: uuid!, $zoneId: String!) {\n  user: user_by_pk(uid: $uid) {\n    avatar\n    avatar_thumbnail\n    last_name\n    last_name_furigana\n    mobile_phone\n    name\n    name_furigana\n    position\n    is_display\n    is_active\n    id\n    role {\n      name\n      id\n    }\n    zone {\n      plan {\n        name\n        max_user\n      }\n      name\n    }\n  }\n  user_aggregate(where: {zone_id: {_eq: $zoneId}, deleted_at: {_is_null: true}, is_incognito: {_eq: false}}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from 'graphql-tag';
export var GET_USER = gql(_templateObject());
export var FOUND_USER = gql(_templateObject2());
export var ACCOUNT_FOUND = gql(_templateObject3());
export var LOGIN = gql(_templateObject4());
export var MY_AVATAR = gql(_templateObject5());