import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  redirect: function redirect(state) {
    return state.user.redirect;
  },
  companyCode: function companyCode(state) {
    return state.user.company_code;
  },
  companyUid: function companyUid(state) {
    return state.user.company_uid;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  chatRooms: function chatRooms(state) {
    return state.chat.rooms;
  },
  chatOwnersSearching: function chatOwnersSearching(state) {
    return state.chat.searching;
  },
  chatOwners: function chatOwners(state) {
    return state.chat.owners;
  },
  chatMessages: function chatMessages(state) {
    return state.chat.messages;
  },
  companies: function companies(state) {
    return state.company.companies;
  },
  members: function members(state) {
    return state.company.members;
  },
  member: function member(state) {
    return state.company.member;
  },
  page_top: function page_top(state) {
    return state.company.page_top;
  },
  page_last: function page_last(state) {
    return state.company.page_last;
  },
  page_count: function page_count(state) {
    return state.company.page_count;
  },
  company: function company(state) {
    return state.company.company;
  },
  industryOptions: function industryOptions(state) {
    return state.company.industrys;
  },
  branchOptions: function branchOptions(state) {
    return state.company.branchs;
  },
  displayOptions: function displayOptions(state) {
    return state.company.display;
  },
  industryMajorOptions: function industryMajorOptions(state) {
    return state.company.industry_major;
  },
  industryMiddleOptions: function industryMiddleOptions(state) {
    return state.company.industry_middle;
  },
  industryInfo: function industryInfo(state) {
    return state.company.industry_info;
  },
  loginOptions: function loginOptions(state) {
    return state.company.login;
  },
  jobTypeOptions: function jobTypeOptions(state) {
    return state.company.occupations;
  },
  certificationMarks: function certificationMarks(state) {
    return state.company.certificationMarks;
  },
  companieSearching: function companieSearching(state) {
    return state.company.searching;
  },
  companieSearchCompanyName: function companieSearchCompanyName(state) {
    return state.company.searchCompanyName;
  },
  companieSearchCtoName: function companieSearchCtoName(state) {
    return state.company.searchCtoName;
  },
  companieSearchIndustryName: function companieSearchIndustryName(state) {
    return state.company.searchIndustryName;
  },
  companieSearchTel: function companieSearchTel(state) {
    return state.company.searchTel;
  },
  companyDetail: function companyDetail(state) {
    return state.companyDetail.detail;
  },
  events: function events(state) {
    return state.event.events;
  },
  eventsSearching: function eventsSearching(state) {
    return state.event.searching;
  },
  eventsSearchDateFrom: function eventsSearchDateFrom(state) {
    return state.event.searchDateFrom;
  },
  eventsSearchDateTo: function eventsSearchDateTo(state) {
    return state.event.searchDateTo;
  },
  eventsSearchStatus: function eventsSearchStatus(state) {
    return state.event.searchStatus;
  },
  coupons: function coupons(state) {
    return state.coupon.coupons;
  },
  couponsSearching: function couponsSearching(state) {
    return state.coupon.searching;
  },
  couponsSearchDateFrom: function couponsSearchDateFrom(state) {
    return state.coupon.searchDateFrom;
  },
  couponsSearchDateTo: function couponsSearchDateTo(state) {
    return state.coupon.searchDateTo;
  },
  couponsSearchExpiredDate: function couponsSearchExpiredDate(state) {
    return state.coupon.searcExpiredDate;
  },
  couponsSearchStatus: function couponsSearchStatus(state) {
    return state.coupon.searchStatus;
  },
  eventCompanySelectEventId: function eventCompanySelectEventId(state) {
    return state.eventCompany.selectEventId;
  },
  eventCompanySearching: function eventCompanySearching(state) {
    return state.eventCompany.searching;
  },
  eventCompanySearchName: function eventCompanySearchName(state) {
    return state.eventCompany.searchName;
  },
  eventCompanySearchRepresentative: function eventCompanySearchRepresentative(state) {
    return state.eventCompany.searchRepresentative;
  },
  eventCompanySearchCategory: function eventCompanySearchCategory(state) {
    return state.eventCompany.searchCategory;
  },
  eventCompanySearchPhoneNumber: function eventCompanySearchPhoneNumber(state) {
    return state.eventCompany.searchPhoneNumber;
  },
  eventCompanyCompanys: function eventCompanyCompanys(state) {
    return state.eventCompany.companys;
  },
  eventCompanyEvent: function eventCompanyEvent(state) {
    return state.eventCompany.event;
  },
  eventCouponSelectEventId: function eventCouponSelectEventId(state) {
    return state.eventCoupon.selectEventId;
  },
  eventCouponSearching: function eventCouponSearching(state) {
    return state.eventCoupon.searching;
  },
  eventCouponSearchName: function eventCouponSearchName(state) {
    return state.eventCoupon.searchName;
  },
  eventCouponSearchEventName: function eventCouponSearchEventName(state) {
    return state.eventCoupon.searchEventName;
  },
  eventCouponSearchStartDateFrom: function eventCouponSearchStartDateFrom(state) {
    return state.eventCoupon.searchStartDateFrom;
  },
  eventCouponSearchStartDateTo: function eventCouponSearchStartDateTo(state) {
    return state.eventCoupon.searchStartDateTo;
  },
  eventCouponCompanys: function eventCouponCompanys(state) {
    return state.eventCoupon.companys;
  },
  eventPreCompanyEvents: function eventPreCompanyEvents(state) {
    return state.eventPreCompany.events;
  },
  eventPreCompanySearching: function eventPreCompanySearching(state) {
    return state.eventPreCompany.searching;
  },
  eventPreCompanySearchDateFrom: function eventPreCompanySearchDateFrom(state) {
    return state.eventPreCompany.searchDateFrom;
  },
  eventPreCompanySearchDateTo: function eventPreCompanySearchDateTo(state) {
    return state.eventPreCompany.searchDateTo;
  },
  eventPreCompanySearchStatus: function eventPreCompanySearchStatus(state) {
    return state.eventPreCompany.searchStatus;
  },
  userProfilesSearching: function userProfilesSearching(state) {
    return state.userProfiles.searching;
  },
  userProfilesSearchName: function userProfilesSearchName(state) {
    return state.userProfiles.searchName;
  },
  userProfilesSearchPhoneNumber: function userProfilesSearchPhoneNumber(state) {
    return state.userProfiles.searchPhoneNumber;
  },
  userProfilesSearchSchoolType: function userProfilesSearchSchoolType(state) {
    return state.userProfiles.searchSchoolType;
  },
  userProfilesSearchSchoolName: function userProfilesSearchSchoolName(state) {
    return state.userProfiles.searchSchoolName;
  },
  userProfilesUsers: function userProfilesUsers(state) {
    return state.userProfiles.users;
  },
  newsSearching: function newsSearching(state) {
    return state.news.searching;
  },
  newsSearchDateFrom: function newsSearchDateFrom(state) {
    return state.news.searchDateFrom;
  },
  newsSearchDateTo: function newsSearchDateTo(state) {
    return state.news.searchDateTo;
  },
  newsSearchPush: function newsSearchPush(state) {
    return state.news.searchPush;
  },
  newss: function newss(state) {
    return state.news.newss;
  },
  fbuser: function fbuser(state) {
    return state.user.user;
  },
  affiliations: function affiliations(state) {
    return state.company.affiliationList;
  },
  pathToListItem: function pathToListItem(state) {
    return state.company.pathToListItem;
  },
  bulletins: function bulletins(state) {
    return state.bulletin.bulletins;
  },
  pageBulletinCount: function pageBulletinCount(state) {
    return state.bulletin.page_count;
  },
  pageBulletinTotal: function pageBulletinTotal(state) {
    return state.bulletin.page_total;
  },
  bulletinCategories: function bulletinCategories(state) {
    return state.bulletin.bulletinCategories;
  },
  bulletinById: function bulletinById(state) {
    return state.bulletin.bulletinById;
  },
  userRoles: function userRoles(state) {
    return state.company.userRoles;
  },
  userRoleByIdList: function userRoleByIdList(state) {
    return state.company.userRoleByIdList;
  },
  searchUserCount: function searchUserCount(state) {
    return state.company.search_user_count;
  },
  myUser: function myUser(state) {
    return state.profile.myUser;
  },
  situation: function situation(state) {
    return state.bulletin.situation;
  },
  suggestions: function suggestions(state) {
    return state.suggestion.suggestions;
  },
  suggestionCategories: function suggestionCategories(state) {
    return state.suggestion.suggestionCategories;
  },
  pageSuggestionCount: function pageSuggestionCount(state) {
    return state.suggestion.page_count;
  },
  pageSuggestionTotal: function pageSuggestionTotal(state) {
    return state.suggestion.page_total;
  },
  suggestionById: function suggestionById(state) {
    return state.suggestion.suggestionById;
  },
  roleDefault: function roleDefault(state) {
    return state.company.roleDefault;
  },
  suggestionComment: function suggestionComment(state) {
    return state.suggestion.suggestionComment;
  },
  commentLimit: function commentLimit(state) {
    return state.suggestion.commentLimit;
  },
  commentTotal: function commentTotal(state) {
    return state.suggestion.commentTotal;
  }
};
export default getters;