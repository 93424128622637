import _taggedTemplateLiteral from "/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\nquery getBulletinCategories {\n  bulletin_categories(order_by: {created_at: desc}) {\n    id\n    name\n  }\n}\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\nquery bulletinQueryById($id: Int!) {\n  bulletin: bulletin_by_pk(id: $id) {\n    content\n    title\n    created_at\n    publish_at\n    push_notification\n    id\n    category_id\n    attach_files(where: {bulletin_id: {_eq: $id}}, order_by: {created_at: desc}) {\n      file_name\n      url\n    }\n    bulletin_surveys {\n      survey {\n        question\n        choice_type\n        is_public_result\n        id\n        survey_answers {\n          content\n          id\n        }\n      }\n    }\n    user {\n      name\n      last_name\n    }\n  }\n}\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nquery getBulletins($keyword: String, $page_limit: Int, $page_offset: Int, $categoryId: Int) {\n  bulletin: search_bulletin_admin_site(args: {page_limit: $page_limit, page_offset: $page_offset, keyword: $keyword, categoryid: $categoryId}, order_by: {publish_at: desc}) {\n    content\n    title\n    publish_at\n    created_at\n    id\n    category {\n      name\n    }\n    bulletin_surveys {\n      survey {\n        id\n        survey_answers {\n          id\n        }\n      }\n    }\n    user {\n      name\n      last_name\n    }\n  }\n  bulletin_count: search_bulletin_admin_site_aggregate(args: {keyword: $keyword, categoryid: $categoryId, page_limit: null, page_offset: null}) {\n    aggregate {\n      count(columns: id)\n    }\n  }\n}\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from 'graphql-tag';
export var GET_BULLETINS = gql(_templateObject());
export var GET_BULLETIN_BY_ID = gql(_templateObject2());
export var GET_BULLETIN_CATEGORIES = gql(_templateObject3());