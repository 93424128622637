import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function setCompanyCode(code) {
  return Cookies.set('CompanyCode', code);
}
export function getCompanyCode() {
  return Cookies.get('CompanyCode');
}
export function removeCompanyCode() {
  return Cookies.remove('CompanyCode');
}
export function setPerCompanyCode(code) {
  return Cookies.set('PerCompanyCode', code);
}
export function getPerCompanyCode() {
  return Cookies.get('PerCompanyCode');
}
export function removePerCompanyCode() {
  return Cookies.remove('PerCompanyCode');
}
export function setEventDetailCode(code) {
  return Cookies.set('EventDetailCode', code);
}
export function getEventDetailCode() {
  return Cookies.get('EventDetailCode');
}
export function removeEventDetailCode() {
  return Cookies.remove('EventDetailCode');
}
export function setEditCompanyCode(code) {
  return Cookies.set('EditCompanyCode', code);
}
export function getEditCompanyCode() {
  return Cookies.get('EditCompanyCode');
}
export function removeEditCompanyCode() {
  return Cookies.remove('EditCompanyCode');
}
export function setPerEventCode(code) {
  return Cookies.set('PerEventCode', code);
}
export function getPerEventCode() {
  return Cookies.get('PerEventCode');
}
export function removePerEventCode() {
  return Cookies.remove('PerEventCode');
}
export function setCouponCode(code) {
  return Cookies.set('CouponCode', code);
}
export function getCouponCode() {
  return Cookies.get('CouponCode');
}
export function removeCouponCode() {
  return Cookies.remove('CouponCode');
}
export function setCouponCompanyCode(code) {
  return Cookies.set('CouponCompanyCode', code);
}
export function getCouponCompanyCode() {
  return Cookies.get('CouponCompanyCode');
}
export function removeCouponCompanyCode() {
  return Cookies.remove('CouponCompanyCode');
}
export function setCouponEventCode(code) {
  return Cookies.set('CouponEventCode', code);
}
export function getCouponEventCode() {
  return Cookies.get('CouponEventCode');
}
export function removeCouponEventCode() {
  return Cookies.remove('CouponEventCode');
}
export function setCouponIndexCode(code) {
  return Cookies.set('CouponIndexCode', code);
}
export function getCouponIndexCode() {
  return Cookies.get('CouponIndexCode');
}
export function removeCouponIndexCode() {
  return Cookies.remove('CouponIndexCode');
}
export function setUserUID(code) {
  return Cookies.set('UserUID', code);
}
export function getUserUID() {
  return Cookies.get('UserUID');
}
export function removeCompanyUID() {
  return Cookies.remove('CompanyUID');
}
export function removeSidebarStatus() {
  return Cookies.remove('sidebarStatus');
}
export function removeSize() {
  return Cookies.remove('sidebarStatus');
}
export function setZoneId(zoneId) {
  return Cookies.set('zoneId', zoneId);
}
export function getZoneId() {
  return Cookies.get('zoneId');
}
export function setHistoryDataFromPage(form) {
  return localStorage.setItem('dataFormPage', form);
}
export function getHistoryDataFromPage() {
  return localStorage.getItem('dataFormPage');
}
export function setIdEditPage(item) {
  return Cookies.set('idBulletinEditPage', item);
}
export function getIdEditPage() {
  return Cookies.get('idBulletinEditPage');
}
export function setUserPermission(user) {
  return localStorage.setItem('userRights', user);
}
export function getUserPermission() {
  return localStorage.getItem('userRights');
}
export function setRoles(role) {
  return localStorage.setItem('role', role);
}
export function getRoles() {
  return localStorage.getItem('role');
}
export function setGoFromPage(path) {
  return Cookies.set('GoFromPage', path);
}
export function getGoFromPage() {
  return Cookies.get('GoFromPage');
}