import "core-js/modules/es6.regexp.to-string";
import request from '@/utils/request'; // import firebase from 'firebase/app'

import 'firebase/auth';
/**
 * ログイン処理
 * @param {username, password} data
 */

export function login(data) {
  var token = {
    token: 'admin-token',
    id: data.username,
    password: data.password
  };
  return {
    code: 20000,
    data: token
  };
  /*
    // const username = process.env.VUE_APP_FIREBASE_DEFAULT_NAME
    // const password = process.env.VUE_APP_FIREBASE_DEFAULT_PASSWORD
    return firebase.auth().signInWithEmailAndPassword(
      data.username,
      data.password
    ).catch((err) => {
      console.log(err.message)
    }).then((response) => {
      if (response !== undefined) {
        var token = {
          token: 'admin-token',
          uid: response.user.uid,
          email: data.username
        }
        return {
          code: 20000,
          data: token
        }
      }
    })
    */
}
/**
 * 内部的なログイン処理
 * @param {username, password} data
 */

/*
function loginInternal(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
*/

/**
 *  ユーザロール
 *  admin, editor
 */

var users_info = {
  'admin-token': {
    roles: ['admin'],
    avatar: 'https://storage.googleapis.com/' + process.env.VUE_APP_GCS_BUCKET + '/assets/parson_no-image.png',
    introduction: 'admin',
    name: 'Super Admin'
  },
  'editor-token': {
    roles: ['editor'],
    avatar: 'https://storage.googleapis.com/' + process.env.VUE_APP_GCS_BUCKET + '/assets/parson_no-image.png',
    introduction: 'editor',
    name: 'Normal Editor'
  }
};

var crypto = require('crypto');

var ENCRYPTION_KEY = process.env.VUE_APP_CRYPT_KEY;
var CRYPT_IV = process.env.VUE_APP_CRYPT_IV;
export function encrypt(text) {
  // var cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), new TextEncoder('utf-8').encode('s80102a54eb75cd7'))
  var cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), unescape(encodeURIComponent(CRYPT_IV)));
  var encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('base64');
}
export function decrypt(text) {
  var decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), new TextEncoder('utf-8').encode(CRYPT_IV));
  var decrypted = decipher.update(Buffer.from(text, 'base64'));
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
export function getInfo(token) {
  var info = users_info[token];
  return new Promise(function (resolve) {
    var result = {
      code: 20000,
      data: info
    };
    resolve(result);
  });
}
export function logout() {
  return new Promise(function (resolve) {
    var result = {
      code: 20000,
      data: 'success'
    };
    resolve(result);
  });
}
export function updateUid(data) {
  return request({
    url: '/adminLogin',
    method: 'post',
    data: data
  });
}